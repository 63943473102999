
import { Options } from "vue-class-component";
import NodesConfigMixins from "../NodesConfigMixins";
@Options({
    name: "flowable-nodes-form-authority-config",
    watch: {
        formPerms: {
            deep: true,
            handler() {
                const set: any = new Set(this.formPerms.map((f: any) => f.perm));
                this.permSelect = set.size === 1 ? set.values()[0] : "";
            },
        },
    },
})
export default class FormAuthorityConfig extends NodesConfigMixins {
    private tableData: any = [];
    private isIndeterminate = false;
    private permSelect = "";
    private checkStatus: any = {
        readOnly: true,
        editable: false,
        hide: false,
    };
    created() {
        //备份
        let oldPermMap = this.formPerms.toMap("id");
        //重新清空，按顺序加载权限
        this.formPerms.length = 0;
        this.formPermsLoad(oldPermMap, this.formItems);
    }
    get formPerms() {
        return this.selectedNode.props.formPerms;
    }

    public allSelect(type: any) {
        this.permSelect = type;
        this.formPerms.forEach((f: any) => (f.perm = type));
    }
    public formPermsLoad(oldPermMap: any, forms: any) {
        forms.forEach((form: any) => {
            if (form.name === "SpanLayout") {
                this.formPermsLoad(oldPermMap, form.props.items);
            } else {
                //刷新名称
                let old = oldPermMap.get(form.id);
                if (old) {
                    old.title = form.title;
                    old.required = form.props.required;
                    this.formPerms.push(old);
                } else {
                    this.formPerms.push({
                        id: form.id,
                        title: form.title,
                        required: form.props.required,
                        perm: this.selectedNode.type === "ROOT" ? "E" : "R",
                    });
                }
            }
        });
    }
    public handleCheckAllChange() {}
}
